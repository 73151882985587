import * as React from "react"
import { graphql } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Layout from "../components/layout"
import SEOComponent from "../components/seo"
import { Hero } from "../components/hero"
import SideCTA from "../components/sideCTA"
import Card from "../components/card"

export const query = graphql`
  query HomePageQuery {
    contentfulHomePage(slug: { eq: "index" }) {
      id
      title
      description
      bannerImage {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      header
      pageContent {
        raw
      }
    }
    contentfulSideCallToAction(title: { eq: "Home - Take the next step CTA" }) {
      id
      contentful_id
      __typename
      title
      image {
        gatsbyImageData
      }
      imageAltText
      cardContent {
        raw
      }
      buttonText
      destination
    }
    contentfulCardCollection(cardCollectionTitle: { eq: "Home" }) {
      cardCollection {
        references {
          id
          heading
          image {
            gatsbyImageData(aspectRatio: 1.5)
          }
          imageAltText
          cardContent {
            raw
          }
          buttonText
          destination
        }
      }
    }
  }
`

const pageContentOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) =>
      children !== "" ? <p className="blocks--paragraph">{children}</p> : "",
    [BLOCKS.LIST_ITEM]: node => <li>{node.content[0].content[0].value}</li>,
  },
}

const sideCTAOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) =>
      children !== "" ? (
        <p className="blocks--paragraph text-xl text-gray-500">
          {children}
        </p>
      ) : (
        ""
      ),
  },
}

const cardCollectionOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) =>
      children !== "" ? (
        <p className="blocks--paragraph font-serif text-gray-500 text-lg">
          {children}
        </p>
      ) : (
        ""
      ),
  },
}

const IndexPage = ({ data }) => {
  const { title, description, bannerImage, header, pageContent } =
    data?.contentfulHomePage

  return (
    <Layout>
      <SEOComponent title={title} description={description} />

      <Hero image={bannerImage.gatsbyImageData} header={header} />

      <section className="container my-6 mx-auto pt-0 sm:px-24 px-4 pb-6">
        <div className="">
          {/* space-y-4 font-serif text-lg sm:text-xl text-gray-500 */}
          <div className="grid gap-4 sm:grid-cols-12 items-center">
            <div className="sm:col-span-12 md:col-span-7 space-y-4 font-serif text-lg sm:text-xl text-gray-500">
              {renderRichText(pageContent, pageContentOptions)}
            </div>

            <div className="my-6 sm:col-span-12 md:col-span-5 text-center">
              <SideCTA
                buttonText={data?.contentfulSideCallToAction.buttonText}
                destination={data?.contentfulSideCallToAction.destination}
              >
                {renderRichText(
                  data?.contentfulSideCallToAction.cardContent,
                  sideCTAOptions
                )}
              </SideCTA>
            </div>
          </div>
        </div>
      </section>

      <section className="container my-4 mx-auto pt-0 sm:px-24 px-4 pb-6">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5">
          {data.contentfulCardCollection.cardCollection.references.map(card => (
            <Card
              key={card.id}
              h2={card.heading}
              image={card.image.gatsbyImageData}
              alt={card.imageAltText}
              buttonText={card.buttonText}
              destination={card.destination}
              textAlignment="center"
            >
              {renderRichText(card.cardContent, cardCollectionOptions)}
            </Card>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
